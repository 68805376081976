/* ImageGallery.css */
.image-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allow images to wrap to the next line */
  }
  
  .image-gallery-item {
    margin: 10px; /* Add spacing between images */
  }
  
  .image-gallery-item img {
    max-width: 100%; /* Limit image width to container width */
    height: 25rem; /* Maintain aspect ratio */
  }

.image-gallery-container {
    position: relative; /* Position relative for button positioning */
  }

   /* BUTTONS */
    
  .image-gallery-buttons {
    position: absolute;
    top: 50%; /* Position at vertical center */
    transform: translateY(-50%);
    width: 100%; /* Full width */
    display: flex;
    justify-content: space-between; /* Space evenly between buttons */
  }
  
  .image-gallery-buttons button {
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    background-color: #0047AB; /* Blue background color */
    color: #ffffff; /* White text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .image-gallery-buttons button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  