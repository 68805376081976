.App {
  text-align: center;
   background-color: #f9f9f9;
  }
  
  .section {
    background-color: #fff; /* White section background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 10px;
    margin: 20px auto;
  }

  /* .sectionimg {
    background-image: url('./components/home/pooltable.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
    opacity: 0.75;
    height: auto; 
  } */
  


/* .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #D9D9D9;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 1;
} */

.horizontal-row {
  height: 2px;
  padding: 10px;
  display: flex;
  justify-content: center;
  /* color: #3E2723; */
  color: #fff;
  width: 50%;
  margin: auto;
}

.homeimagealign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.qhallimg {
  width: 100%;
  max-width: 600px;
  height: auto;
  /* margin-right: 20px; */
  /* margin: 10px; */
  padding: 5px;
  border: 5px solid #0047AB;
}

.pool-stick1{
  margin-bottom: 0px;
  padding-bottom: 0px;
  width: 50%;
  max-width: 100%;
  height: auto;
}

.pool-stick2{
  margin-bottom: 0px;
  padding-bottom: 0px;
  transform: scaleX(-1);
  width: 50%;
  max-width: 100%;
  height: auto;
}

/* .dartimg {
height: 25rem;
padding-bottom: 20px;
}

.hatchetimg {
  height: 25rem;
} */


.logo {
  max-width: 100%;
  height: auto;
  display: block; 
  margin: 0 auto; 
  min-width: 15%;
}

/* Media query for larger screens (adjust the screen width as needed) */
@media (min-width: 1024px) {
  .logo {
    width: 100%; /* Ensure logo expands to fill the available width */
    min-width: initial; /* Reset the minimum width for larger screens */
  }
}


.navbar {
  padding: 0px;
  background-image: url('./components/nav/dark-brown-navbar-image-wood.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border: 1px solid #8B4513;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
}

.navbar a {
  color: #fff;
  text-align: center;
  padding: 8px 12px; /* Adjust padding for navigation items */
  margin: 0;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 12px;
}

.navbar a:hover {
  color: #8B4513;
}

.navbar a.active {
  background-color: #8B4513;
  color: #fff;
}

/* Media query for adjusting padding on smaller screens */
@media screen and (max-width: 768px) {
  .navbar a {
    padding: 8px; /* Reduce padding on smaller screens */
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

.qimgdiv {
  padding-bottom: 10px;
}

.location {
  padding-top: 10px;
}

.homeheadertext {
  padding: 10px;
  color: #3E2723;
  z-index: 2;
}

.homebodytext{
  /* color: #0047AB; */
  color: #fff;
  font-size: 2.5rem;
  font-family: 'Roboto Slab';
  /* background-color: rgba(0, 71, 171, 0.5); */
}

.activities-header-text {
  color:#0047AB;
  font-size: 2.5rem;
  font-family: 'Roboto Slab';
}

.textbackground {
  background-color: #0047AB;
  background-image: linear-gradient(to bottom, #0047AB, #B87333); 
  padding: 20px; /* Padding around the highlighted section */
  border-radius: 8px; /* Rounded corners for the highlight */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Soft shadow effect */
  margin: auto; /* Center the section horizontally */
  max-width: 600px; /* Limit the width of the section */
}



.homebodytextp{
  color: #fff;
  font-size: 1.5rem;

}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  color: #0047AB;
  text-align: center;
  font-size: 10px;
  padding-top: 10px;
}

.footer h2 {
  font-size: 30px;
  font-family:'Roboto Slab', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


/* Additional Styles for Responsiveness */
